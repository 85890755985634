@use '@angular/material' as mat;
@import '@angular/material/theming';
@import "variables";
@import "mixins";

@include mat.core();

// $primary: mat-palette($mat-red, 900, A100, #7f0000); // incomptabile ng-13 / 14, le param #7f0000
$primary: mat.define-palette($mat-red, 900, A100);
$primary: map-merge($primary, (darker: 700));
$accent:  mat.define-palette($mat-blue, A400, A200, A700);
$warn:    mat.define-palette($mat-red, 500);

$theme: mat.define-light-theme($primary, $accent, $warn);

@include mat.all-component-themes($theme);

.p8-dark-theme {
  $input-primary: mat.define-palette($mat-grey, 50, 50, 50);
  $input-accent: mat.define-palette($mat-grey, 50, 50, 50);
  $input-warn: mat.define-palette($mat-blue, 900, A100);

  $dark-theme: mat.define-dark-theme($primary, $accent, $warn);
  @include mat.all-component-themes($dark-theme);

  $input-theme: mat.define-dark-theme($input-primary, $input-accent, $input-warn);
  @include mat.legacy-form-field-theme($input-theme);
}

$p8-default-padding: 24px !default;

$input-primary: mat.define-palette($mat-blue, 900, A700);
$input-accent:  mat.define-palette($mat-blue-grey, A200, A100, A400);
$input-warn: mat.define-palette($mat-red, 900, A100);

$input-theme: mat.define-light-theme($input-primary, $input-accent, $input-warn);

//@include mat-autocomplete-theme($input-theme);
//@include mat-form-field-theme($input-theme);
//@include mat-input-theme($input-theme);
//@include mat-select-theme($input-theme);
@include mat.legacy-autocomplete-theme($input-theme);
@include mat.legacy-form-field-theme($input-theme);
@include mat.legacy-input-theme($input-theme);
@include mat.legacy-select-theme($input-theme);

@function mat-sidenav-custom() {
  $new-background: (
    card:       map-get($mat-grey, 900),
    dialog:     map-get($mat-grey, 900),
    hover:      rgba(white, 0.1),
    raised-button: map-get($mat-grey, 900),
    selected-button: map_get($mat-grey, 700),
    selected-disabled-button: map_get($mat-grey, 900),
  );

  $new-foreground: (
    icons:             map-get($mat-grey, 200),
    text:              map-get($mat-grey, 200),
  );

  $theme2: (
    background: map-merge(map-get($theme, background), $new-background),
    foreground: map-merge(map-get($theme, foreground), $new-foreground),
  );

  @return map-merge($theme, $theme2);
}

.global__mat-sidenav-custom {
  $sidenav-theme: mat-sidenav-custom();
  @include mat.all-component-themes($sidenav-theme);

  .app-cm__home_sidenav-active {
    background: map-get($primary, default);
  }
  .mat-mdc-list-item:hover:not(.app-cm__home_sidenav-active) {
    background:  #484848;
  }
  $background: map-get($sidenav-theme, background);
  background-color: map-get($background, dialog);
}

@function mat-drawer-custom() {
  $background: map-get($theme, background);
  $new-background: (
    background: map-get($background, dialog),
    card:       map-get($background, background),
    dialog:     map-get($background, background),
  );

  $theme2: (
    background: map-merge($background, $new-background),
  );

  @return map-merge($theme, $theme2);
}

.global__mat-drawer-custom {
  @include mat-sidenav-theme(mat-drawer-custom());
}

// Make overlay appear on hover, over icon buttons as well
.mat-icon-button:hover .mat-button-focus-overlay {
  opacity: 0.2;
}

.global__tooltip {
  font-size: 14px;
}

.fill-space {
  flex: 1 1 auto;
}

.max-text-width {
  max-width: 800px;
}

strong, b {
  font-weight: 500;
}

em, i {
  font-style: italic;
}

.p8-primary {
  color: map-get($primary, default);
}

.p8-accent {
  color: map-get($accent, default);
}

.p8-secondary {
  color: $p8-secondary;
}

.p8-justify {
  text-align: justify;
}

.p8-bold {
  font-weight: 700;
}

.p8-normal {
  font-weight: 400;
}

.p8-medium {
  font-weight: 500;
}

.p8-18 {
  @include p8-icon-size(18px)
}

.p8-24 {
  @include p8-icon-size(24px)
}

.p8-36 {
  @include p8-icon-size(36px)
}

.p8-48 {
  @include p8-icon-size(48px)
}

.mat-mdc-card{
  padding: $p8-mat-card-default-padding;
}
