html {
  --mat-badge-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(18px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(18px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font-size: 112px;
  font-weight: 300;
  line-height: 112px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font-size: 56px;
  font-weight: 400;
  line-height: 56px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

html {
  --mat-bottom-sheet-container-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 18px;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-standard-button-toggle-text-font: Roboto, "Helvetica Neue", sans-serif;
}

html {
  --mat-datepicker-calendar-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-expansion-header-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-expansion-header-text-size: 15px;
  --mat-expansion-header-text-weight: 400;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 18px;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 18px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 18px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-stepper-container-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-stepper-header-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-stepper-header-label-text-size: 18px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-size: 14px;
  --mat-stepper-header-selected-state-label-text-weight: 500;
}

html {
  --mat-toolbar-title-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: normal;
  --mat-toolbar-title-text-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 18px;
}

html {
  --mat-option-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 15px;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 15px;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 400;
}

.mat-mdc-card {
  --mat-card-title-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: normal;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-card-subtitle-text-line-height: 24px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: normal;
  --mat-card-subtitle-text-weight: 500;
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
}

html {
  --mdc-filled-text-field-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-filled-text-field-label-text-size: 15px;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-outlined-text-field-label-text-size: 15px;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 15px;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 15px;
  --mat-form-field-subscript-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: normal;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-trigger-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 15px;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 400;
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 15px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 18px;
  --mdc-chip-label-text-tracking: normal;
  --mdc-chip-label-text-weight: 400;
}

.mat-mdc-slide-toggle {
  --mat-slide-toggle-label-text-size: 18px;
  --mat-slide-toggle-label-text-tracking: normal;
  --mat-slide-toggle-label-text-line-height: 20px;
  --mat-slide-toggle-label-text-weight: 400;
}
.mat-mdc-slide-toggle .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
}

.mat-mdc-radio-button .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 18px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-slider {
  --mdc-slider-label-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 24px;
  --mdc-slider-label-label-text-tracking: normal;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-item-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-menu-item-label-text-size: 15px;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 400;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 15px;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 18px;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: normal;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

html {
  --mat-paginator-container-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: normal;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: normal;
  --mat-tab-header-label-text-line-height: 14px;
  --mat-tab-header-label-text-weight: 500;
}

.mat-mdc-checkbox .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 18px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}

.mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-mdc-extended-fab {
  --mdc-extended-fab-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: normal;
  --mdc-extended-fab-label-text-weight: 500;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 18px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-header-headline-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-table-header-headline-line-height: 24px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: normal;
  --mat-table-row-item-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 18px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 18px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: normal;
}